import React from "react";
import Home from "./Home";
import Services from "./Services";
import About from "./About";
import './Section.scss';
import Contactus from "./Contactus";

const Section = ({title, subtitle, id}) => {
    if(subtitle === Home) {
        return (
            <div className={"section"}>     
              <div id={id}>                      
                  <Home/>
              </div> 
            </div>
        );
    } else if(subtitle === About) {
      return (
          <div className={"section"}>
            <div  className="section-content" id={id}>
              <h1><span>{title}</span></h1>
              <About />
            </div>
          </div>
      );
  } else if(subtitle === Services) {
        return (
            <div className={"section"}>
              <div className="section-content bgColor" id={id}>
              <h1><span>{title}</span></h1>
                <Services />
              </div>
            </div>
        );
    } else {
        return (
            <div className={"section"}>
              <div  className="section-content" id={id}>
              <h1><span>{title}</span></h1>
                <Contactus />
              </div>
            </div>
        );
    }
    
}
export default Section;