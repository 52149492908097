import React from 'react';
import squint from './assets/img/squint.png';
import redEye from './assets/img/redEye.jpg';
import lazyEye from './assets/img/lazyEye.jpg';
import refractive from './assets/img/refractive1.png';
import './Services.scss';

const Services = () => {
    return (
        <div>                        
            <div className="cards">
                <div className="card card--supervisor">
                    <div className="card__icon">
                        <img src={refractive} alt="" />
                    </div>
                    <h2 className="card__heading">Pediatric Refractive Error Management</h2>
                    <p className="card__text">
                        Refractive errors are a type of vision problem that makes it hard to see clearly.
                    </p>   
                    <a className='knowMore' href="#openModal-refractive">Know more</a>                    
                    <div id="openModal-refractive" className="modalDialog">
                        <div>
                            <a href="#close" title="Close" className="close">X</a>
                            <h2>Pediatric Refractive Error Management</h2>
                            <p>Children unlike adults may not be always able to appreciate decreased vision, especially in younger age groups. So a meticulous eye examination becomes crucial in kids. The doctor will put eye drops to relax the eye muscles and check the need for glasses. The number can be a sphere or a cylinder in the form of a plus or minus lens, or combination of the above. </p>
                        </div>
                    </div>                
                </div>

                <div className="cards__middle">
                <div className="card card--team-builder">
                    <div className="card__icon">
                        <img src={squint} alt="" />
                    </div>
                    <h2 className="card__heading">Squint Evaluation And Management (All Age Groups)</h2>
                    <p className="card__text">
                        A squint, also called strabismus, is where the eyes point in different directions.
                    </p> 
                    <a className='knowMore' href="#openModal-squint">Know more</a>                    
                    <div id="openModal-squint" className="modalDialog">
                        <div>
                            <a href="#close" title="Close" className="close">X</a>
                            <h2>Squint Evaluation And Management (All Age Groups)</h2>
                            <p>Squint or crossed eyes is a disorder wherein the eyes focus in different directions while viewing. Squint warrants eye examination since its unlikely to go on its own. Unlike popularly conceived, squint should be treated in childhood to prevent irreversible damage to vision. Some squints may need additional neurological tests for diagnosis. Depending on the type of squint, treatment could be spectacles or surgical.  </p>
                        </div>
                    </div>                         
                </div>

                <div className="card card--karma">
                    <div className="card__icon">
                        <img src={lazyEye} alt="" />
                    </div>
                    <h2 className="card__heading">Lazy Eye (Amblyopia)</h2>
                    <p className="card__text">
                        Lazy eye (amblyopia) is reduced vision in one eye caused by abnormal visual development early in life.
                    </p>
                    <a className='knowMore' href="#openModal-lazyEye">Know more</a>                    
                    <div id="openModal-lazyEye" className="modalDialog">
                        <div>
                            <a href="#close" title="Close" className="close">X</a>
                            <h2>Lazy Eye (Amblyopia)</h2>
                            <p>In this there is decreased vision in one or both the eyes due to changes in the brain owing to poor vision in early childhood. The poor vision may be due to a refractive error, squint, cataract etc. In case of amblyopia in one eye , the brain gives preference to one eye over the other. Diagnosis of amblyopia in kids  may be delayed sine they may not be able to appreciate decreased vision in one eye. It is treated by correcting the underlying comdition and patching.  </p>
                        </div>
                    </div>                     
                </div>
                </div>
                <div className="card card--calculator">
                    <div className="card__icon">
                        <img src={redEye} alt="" />
                    </div>
                    <h2 className="card__heading">Red Eyes</h2>
                    <p className="card__text">
                        Red eyes occur when the vessels in your eye become swollen or irritated
                    </p>  
                    <a className='knowMore' href="#openModal-redEye">Know more</a>                    
                    <div id="openModal-redEye" className="modalDialog">
                        <div>
                            <a href="#close" title="Close" className="close">X</a>
                            <h2>Red Eyes</h2>
                            <p>Can be due to infections , allergies commonly. The patient may have accompanying watering, discharge, itching etc. Treatment constitutes anti allergic agents, antibiotics depending on the underlying condition.   </p>
                        </div>
                    </div>                   
                </div>
            </div>
        </div>
    )
}

export default Services;