import Navbar from './Navbar';
import Section from './Section';
import Contactus from "./Contactus";
import Home from "./Home";
import Services from "./Services";
import About from "./About";
import { MDBRow, MDBCol, MDBContainer  } from 'mdb-react-ui-kit';
import './Header.css';
import logo from './logo.gif';
import { MDBIcon } from 'mdb-react-ui-kit';

const Header = () => {
    return (
        <div>
            <MDBContainer>
                <MDBRow className='p-2'>
                    <MDBCol md='3' className="logoContainer">
                        <img src={logo} className="nav-logo" alt="Logo" />
                    </MDBCol>
                    <MDBCol md='9' className='headerDetails_container'>
                        <MDBRow className='headerDetails'>
                            {/* <MDBCol md='4'>
                                <MDBRow className='headerDetailsRow'>
                                    <div className='headerIcon'>
                                        <MDBIcon className='ms-1' icon='phone-square-alt' size='2x' />
                                    </div>
                                    <MDBCol className='headerDetails__info' md='9'>
                                        <MDBRow>+91 9326593107</MDBRow>
                                    </MDBCol> 
                                </MDBRow>                               
                            </MDBCol> */}
                            <MDBCol md='5'>
                                <MDBRow className='headerDetailsRow'>
                                    <div className='headerIcon'>
                                        <MDBIcon className='ms-1' icon='map-marker-alt' size='2x' />
                                        <MDBRow className='headerDetails__info'>Rajendra nagar Borivali East, Mumbai</MDBRow>
                                    </div> 
                                </MDBRow>                               
                            </MDBCol>
                            <MDBCol md='5'>
                                <MDBRow className='headerDetailsRow'>
                                    <div className='headerIcon'>
                                        <MDBIcon className='ms-1' icon='envelope' size='2x' />
                                        <MDBRow className='headerDetails__info'>gouravi.pandit@tejomayclinics.com</MDBRow>
                                    </div> 
                                </MDBRow>                               
                            </MDBCol>
                        </MDBRow>
                        <div className='doctor-details__phone ps-5 d-inline'></div>
                    </MDBCol>                
                </MDBRow>  
            </MDBContainer>                                  
            <Navbar />
            <Section title="Home" subtitle={Home} id="home" />
            <Section title="About" subtitle={About} id="about" />
            <Section title="Services" subtitle={Services} id="services" />
            <Section title="Contact Us" subtitle={Contactus} id="contactus" /> 
            
        </div>              
    );
}

export default Header;