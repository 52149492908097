import React from 'react';
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import image1 from './assets/img/gallery/image1.jpg';
import image2 from './assets/img/gallery/image2.jpg';
import image4 from './assets/img/gallery/image4.jpg';
import image8 from './assets/img/gallery/image8.jpg';
import image10 from './assets/img/gallery/image10.jpg';
import image11 from './assets/img/gallery/image11.jpg';
import image13 from './assets/img/gallery/image13.jpg';
import image17 from './assets/img/gallery/image17.jpg';
import image19 from './assets/img/gallery/image19.jpg';
import profile_pic from './assets/img/profile_pic1.jpg';
import './About.scss';

const { useState } = React;

//IMAGES
//you can also import a local file, the syntax would look like:
//import image1 from './images/imagename.jpg'

//IMAGE ARRAY
const images = [image1, image2, image4, image8, image10, image11, image13, image17, image19];


//MAIN APP COMPONENT
function About() {
  return (
    <MDBContainer className='aboutUs_container'>
      <MDBRow className='mb-5 aboutUs_container_first'>
        <MDBCol md='6' className='about-doctor__image'>
            <img src={profile_pic} alt=''/>
        </MDBCol>
        <MDBCol md='6' className='about-doctor__details'>
            <h2 className='about-doctor__name'>Dr Gouravi Pandit </h2>
            <p>
              Dr Gouravi Pandit is a Pediatric Ophthalmologist and Squint specialist(MBBS, MS ,FPOS, FICO). 
              She completed her MS in Ophthalmolgy from Regional institute of Ophthalmology, 
              Medical college Kolkata. She further specialized in Pediatric Ophthalmology and strabismus by undergoing a long term fellowship training 
              programme under GPOS Mumbai. She specializes in management of varied pediatric eye disorders like refractive errors, lazy eye, squint,cataract, 
              nasolacrimal duct obstruction, nystagmus etc.
            </p>
        </MDBCol>
      </MDBRow>
      <MDBRow className='mt-5'>
        <div className="gallerySection">  
          <h2 className='gallery__name'>Gallery</h2>         
          <ImageGallery />
        </div>
      </MDBRow>
    </MDBContainer>    
  );
}


//MAIN LIGHTBOX
//Holds Images Cards and Lightbox
//this is where all of our logic will live
function ImageGallery() {
  const [imageToShow, setImageToShow] = useState("");
  const [lightboxDisplay, setLightBoxDisplay] = useState(false);
  
  //looping through our images array to create img elements
  const imageCards = images.map((image) => (
    <img className="image-card" onClick={() => showImage(image)} src={image} alt=''/>
  ));

  //function to show a specific image in the lightbox, amd make lightbox visible
  const showImage = (image) => {
    setImageToShow(image);
    setLightBoxDisplay(true);
  };

  //hide lightbox
  const hideLightBox = () => {
    setLightBoxDisplay(false);
  };

  //show next image in lightbox
  const showNext = (e) => {
    e.stopPropagation();
    let currentIndex = images.indexOf(imageToShow);
    if (currentIndex >= images.length - 1) {
      setLightBoxDisplay(false);
    } else {
      let nextImage = images[currentIndex + 1];
      setImageToShow(nextImage);
    }
  };

  //show previous image in lightbox
  const showPrev = (e) => {
    e.stopPropagation();
    let currentIndex = images.indexOf(imageToShow);
    if (currentIndex <= 0) {
      setLightBoxDisplay(false);
    } else {
      let nextImage = images[currentIndex - 1];
      setImageToShow(nextImage);
    }
  };
  

  return (
    <>
      <div>{imageCards}</div>
      
      {
        lightboxDisplay ? 
        <div id="lightbox" onClick={hideLightBox}>
          <button onClick={showPrev}>⭠</button>
          <img id="lightbox-img" src={imageToShow} alt=''></img>
          <button onClick={showNext}>⭢</button>
        </div>
       : ""
      }
    </>
  );
}

export default About;