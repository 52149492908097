import './App.css';
import Header from './Header';
import { MDBContainer, MDBFooter, MDBIcon, MDBBtn, MDBRow  } from 'mdb-react-ui-kit';

function App() {
  return (
    <div className="App">
        <Header />
        <MDBFooter className='text-center text-white footerContainer' style={{ backgroundColor: '#2A367A' }}>
            <MDBContainer className='pt-4'>                
                <section className="">
                    <div className="row">  
                        <div className="col-lg-2 col-md-1 mb-0 footerEmpty_container"></div>      
                        <div className="col-lg-4 col-md-6 mb-4 footerHeader_Container">
                            <MDBRow className='footer_header'>                                
                                <div className='footer_addressEmailContainer footer_detailsHeader'>
                                    Reach Us
                                </div>                
                            </MDBRow>
                            <MDBRow className='footer_addressEmail'>
                                <div className='footer_addressEmailContainer'>
                                    <MDBIcon className='ms-1 footer_addressEmailIcon' icon='map-marker-alt' size='1x' />
                                    <MDBRow className='footer_addressEmailInfo'>Rajendra nagar Borivali East, Mumbai</MDBRow>
                                </div>
                            </MDBRow> 
                            <MDBRow className='footer_addressEmail'>
                                <div className='footer_addressEmailContainer'>
                                    <MDBIcon className='ms-1 footer_addressEmailIcon' icon='envelope' size='1x' />
                                    <MDBRow className='footer_addressEmailInfo'>gouravi.pandit@tejomayclinics.com</MDBRow> 
                                </div>
                            </MDBRow>
                        </div>        
                        <div className="col-lg-4 col-md-6 mb-4 footerHeader_Container">                            
                            <MDBRow className='footer_header'>                                
                                <div className='footer_addressEmailContainer footer_detailsHeader'>
                                    Timings
                                </div>                
                            </MDBRow>
                            <MDBRow className='footer_addressEmail'>                                
                                <div className='footer_addressEmailContainer'>
                                    <MDBIcon className='ms-1 footer_addressEmailIcon' icon='far fa-clock' size='1x' />
                                    <MDBRow className='footer_addressEmailInfo'>5:30 PM - 7:30 PM , Monday - Saturday</MDBRow> 
                                </div>                
                            </MDBRow>
                            <MDBRow className='footer_addressEmail'>                                
                                <div className='footer_addressEmailContainer'>
                                    <MDBIcon className='ms-1 footer_addressEmailIcon' icon='far fa-clock' size='1x' />
                                    <MDBRow className='footer_addressEmailInfo'>9 AM - 10 AM , Thursday</MDBRow>
                                </div>                
                            </MDBRow>
                        </div> 
                        <div className="col-lg-2 col-md-1 mb-0 footerEmpty_container"></div>                   
                    </div>
                </section>
                <section className='mb-4'>
                    <MDBBtn
                        rippleColor="dark"
                        link="true"
                        floating
                        className='m-1 footerBtn'
                        href='https://www.facebook.com/profile.php?id=100082577838957'
                        role='button'
                        target='_blank'
                    >
                        <MDBIcon fab className='fab fa-facebook-f' />
                    </MDBBtn>                    

                    <MDBBtn
                        rippleColor="dark"
                        link="true"
                        floating
                        className='m-1 footerBtn'
                        href='https://g.co/kgs/Mh1ypP'
                        role='button'
                        target='_blank'
                    >
                        <MDBIcon fab className='fa-google' />
                    </MDBBtn>                
                </section>
            </MDBContainer>

            <div className='text-center p-3 copyRight_text' >
                © 2022 Copyright: 
                <a className='tejomayLink' href='http://www.tejomayclinics.com/'>
                tejomayclinics.com
                </a>
            </div>
        </MDBFooter>
    </div>
  );
}

export default App;
