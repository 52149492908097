import React from 'react';
import {
  MDBCarousel,
  MDBCarouselInner,
  MDBCarouselItem,
  MDBCarouselElement  
} from 'mdb-react-ui-kit';
import { MDBCol, MDBRow } from 'mdbreact';
import './Home.scss';

const Home = () => {
    return (
        <MDBRow>
          <MDBCol md='6' className='homeDetails__left'>
            <div className="box">
              <div className='box_inner'>
                <div className='homeDetails__left_bgImage'></div>
                <h1>
                  Pediatric Ophthalmologist and Squint specialist Services
                </h1>
                <p>
                  Specialities in Pediatric refractive error management, Squint evaluation and management, Lazy eye and Red eyes.
                </p>
              </div>
            </div>           
          </MDBCol>
          <MDBCol md='6' className='homeDetails__right'>
            <MDBCarousel  
              length={3}
              showControls={false}
              showIndicators={false}
              className="z-depth-1"
              slide='true'>
              <MDBCarouselInner>
                <MDBCarouselItem className='active'>
                  <MDBCarouselElement src='https://templatekit.jegtheme.com/optica/wp-content/uploads/sites/124/2021/07/female-oculist-examining-kid-eyes-with-trial-frame-and-lenses.jpg' alt='...' />
                </MDBCarouselItem>
                <MDBCarouselItem>
                  <MDBCarouselElement src='https://templatekit.jegtheme.com/optica/wp-content/uploads/sites/124/2021/07/female-ophthalmologist-examining-kid-eyes-with-trial-frame-and-lenses.jpg' alt='...' />
                </MDBCarouselItem>
                <MDBCarouselItem>
                  <MDBCarouselElement src='https://templatekit.jegtheme.com/optica/wp-content/uploads/sites/124/2021/07/selective-focus-of-ophthalmologist-examining-kid-eyes-with-trial-frame-in-clinic.jpg' alt='...' />
                </MDBCarouselItem>
                <MDBCarouselItem>
                  <MDBCarouselElement src='https://templatekit.jegtheme.com/optica/wp-content/uploads/sites/124/2021/07/view-from-side-of-oculist-checking-sight-of-girl-in-clinic.jpg' alt='...' />
                </MDBCarouselItem>
                <MDBCarouselItem>
                  <MDBCarouselElement src='https://templatekit.jegtheme.com/optica/wp-content/uploads/sites/124/2021/07/selective-focus-of-young-woman-getting-eye-test-by-oculist-in-clinic.jpg' alt='...' />
                </MDBCarouselItem>
                <MDBCarouselItem>
                  <MDBCarouselElement src='https://templatekit.jegtheme.com/optica/wp-content/uploads/sites/124/2021/07/optometrist-looking-at-eye-chart.jpg' alt='...' />
                </MDBCarouselItem>
                <MDBCarouselItem>
                  <MDBCarouselElement src='https://templatekit.jegtheme.com/optica/wp-content/uploads/sites/124/2021/07/female-oculist-examining-kid-eyes-with-trial-frame-and-lenses.jpg' alt='...' />
                </MDBCarouselItem>
              </MDBCarouselInner>
            </MDBCarousel>
          </MDBCol>
        </MDBRow>      
      );
}
export default Home;