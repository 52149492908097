import React from 'react';
import emailjs from 'emailjs-com';
import { MDBRow, MDBCol, MDBContainer, MDBIcon } from 'mdb-react-ui-kit';
import './Contactus.scss';

const Contactus = () => {
    const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs.sendForm('service_pmv0ffn', 'template_bmrfxre', e.target, 'uy3mEA2kf395JfcTh')
          .then((result) => {
              console.log(result.text);
          }, (error) => {
              console.log(error.text);
          });
      };
    return (
        <div>       
            <MDBContainer className='contactUs_section'>
                <MDBRow className='p-2'> 
                    <MDBCol md='5' className='p-2 contactUs_form'>
                        <form onSubmit={sendEmail}>
                            <MDBRow md='6' className='p-2'>
                                {/* <label>Name</label> */}
                                <input required type='text' className='p-3' placeholder='Your Name' name='name'/>
                            </MDBRow>
                            <MDBRow md='6' className='p-2'>
                                {/* <label>Email</label> */}
                                <input required type='email' className='p-3' placeholder='Your Email' name='email'/>
                            </MDBRow>
                            <MDBRow md='6' className='p-2'>
                                {/* <label>Subject</label> */}
                                <input type='text' className='p-3' placeholder='Subject' name='subject'/>
                            </MDBRow>
                            <MDBRow md='6' className='p-2'>
                                {/* <label>Mobile Number</label> */}
                                <input type="text" maxLength="10" pattern="\d{10}" title="Please enter exactly 10 digits" className='p-3' placeholder='Mobile Number' name='mobile_number'/>
                            </MDBRow>
                            <MDBRow md='6' className='p-2'>
                                {/* <label>Message</label> */}
                                <textarea required className='p-3' placeholder='Your Message' name='message'/>
                            </MDBRow>
                            <MDBRow md='6' className='p-2'>
                                <button type='submit' className='p-2 contactUs_submit'>Submit</button>
                            </MDBRow>                                                          
                        </form> 
                    </MDBCol>
                    <MDBCol md='7' className='p-2 px-5 contactUs_map'>
                        <MDBRow>
                            <h2>Get in Touch With Us</h2>
                        </MDBRow>
                        <MDBRow className="gx-5">
                        <iframe title="googleMaps" width="100%" height="400px" id="gmap_canvas" src="https://maps.google.com/maps?q=Tejomay%20clinic%20Ekta%20Bhoomi%20Garden%202,%20L%2025,%20Dattapada%20Rd,%20Rajendra%20Nagar,%20Borivali%20East,%20Mumbai,%20Maharashtra%20400066&t=&z=15&ie=UTF8&iwloc=&output=embed" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0"></iframe>
                        </MDBRow>
                        <MDBRow className="drName_contactNumber">
                            <h4>Tejomay Clinic</h4>
                            <div>
                                <MDBIcon fas icon="phone-square-alt" />
                                <span>(+91) 9326593107 / 9051803296 </span>  
                            </div>
                            
                        </MDBRow>                    
                    </MDBCol>
                </MDBRow>
            </MDBContainer>            
        </div>
    )
}
export default Contactus;