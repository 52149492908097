import React from "react";
import { Link } from "react-scroll";
import { MDBCol, MDBBtn, MDBContainer } from 'mdb-react-ui-kit';
import './Navbar.scss';

const Navbar = () => {
    return (
        <nav className="nav" id="navbar">
            <MDBContainer>
                <div className="nav-content">
                    
                    <MDBCol md='6' >
                        <ul className="nav-items">
                            <li className="nav-item">
                                <Link activeClass="active" to="home" spy={true} smooth={true} offset={-60} duration={500}>Home</Link>
                            </li>
                            <li className="nav-item">
                                <Link activeClass="active" to="about" spy={true} smooth={true} offset={-60} duration={500}>About</Link>
                            </li>
                            <li className="nav-item">
                                <Link activeClass="active" to="services" spy={true} smooth={true} offset={-60} duration={500}>Services</Link>
                            </li>
                            {/* <li className="nav-item">
                                <Link activeClass="active" to="blog" spy={true} smooth={true} offset={-60} duration={500}>Blog</Link>
                            </li> */}
                            <li className="nav-item">
                                <Link activeClass="active" to="contactus" spy={true} smooth={true} offset={-60} duration={500}>Contact Us</Link>
                            </li>
                        </ul>
                    </MDBCol>  
                    <MDBCol md='3' className='align-self-center book-appointment_wrapper'>                    
                        <MDBBtn className='d-flex flex-row-reverse book-appointment__btn' color='#1A237E'>
                            <a target="_blank" rel="noreferrer" href="https://www.picktime.com/910a0309-edf1-4b45-8b4d-c3b387a982aa">                                
                                Book appointment
                            </a>                            
                        </MDBBtn>
                    </MDBCol>          
                </div>
            </MDBContainer>
        </nav>
      );
    
    
}

export default Navbar;